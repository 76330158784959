import ConfigRoutes from "../../config/Routes";
import InfluencersFindCampaigns from "../controllers/InfluencersFindCampaigns";
import InfluencersCandidacies from "../controllers/InfluencersCandidacies";
import InfluencersCampaignDetails from "../controllers/InfluencersCampaignDetails";
import InfluencersCampaignParticipation from "../controllers/InfluencersCampaignParticipation";


export default class Routes extends ConfigRoutes {

    static routes = {
      'InfluencersFindCampaigns': InfluencersFindCampaigns,
      'InfluencersCandidacies': InfluencersCandidacies,
      'InfluencersCampaignDetails': InfluencersCampaignDetails,
      'InfluencersCampaignParticipation': InfluencersCampaignParticipation
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}
