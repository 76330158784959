export default class InfluencersCampaignParticipation {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    $(".submit-link").on("click", function(e){
      var link_number = $(this).data("link");
      var link_url = $("#input-link-" + link_number).val();
      var type_str = $(this).data("type-str");

      var id = $(this).data("campaign");
      var type = $(this).data("type");

      var csrf_token = $('meta[name="csrf-token"]').attr('content');

      if (self.isValidUrl(link_url, type)) {
        $.ajax({
            type: "POST",
            url: "/campaigns/"+ id + "/send-campaign-participation",
            data: { 
              url: link_url,
              type: type,
              authenticity_token: csrf_token
            },
            success: function(response) {
                $(".row-" + link_number).html(`<div class="row align-items-center">
                <div class="mt-1 mt-md-0 col-12 col-md-2 d-flex justify-content-center text-dark font-weight-bold">`+ type_str + ` `+ link_number + `</div>
                
                <div class="mt-1 mt-md-0 col-12 col-md-2 d-flex"><a href="`+ link_url + `" class="text-primary ml-1" target="_blank">Voir sur Instagram</a></div>

                  <div class="text-warning mx-3"> <i class="mdi mdi-alert"></i>  pas encore analysée</div>
                <div class="mt-1 mt-md-0 col-12 col-md-4">État : 
                    <span class="badge ml-2 fs-12 badge-soft-secondary p-1 font-weight-light">En attente</span>
                </div>
              </div>`)

              $("." + type + "-count").html(parseInt($("." + type + "-count").html()) + 1);
            },
            error: function(xhr, status, error) {
                
            }
        });
      } else {
        $(".invalid-" + link_number).removeClass("d-none").addClass("d-block");
      }
    });
  }

  isValidUrl(url, postType) {
    const parsedUrl = new URL(url);

    console.log(postType);
    console.log(parsedUrl.host);
    console.log(parsedUrl.pathname);

    switch (postType) {
        case "post":
          return parsedUrl.host === "www.instagram.com" && /^\/p\/[\w\-]+\/$/.test(parsedUrl.pathname);
        case "reel":
          return (parsedUrl.host === "www.instagram.com" && /^\/reel\/[\w\-]+\/$/.test(parsedUrl.pathname)) ||
                (parsedUrl.host === "www.instagram.com" && /^\/p\/[\w\-]+\/$/.test(parsedUrl.pathname));
        case "story":
          return true
        default:
          return false;
    }
}


  onDestroy(){
  
  }
}