export default class InfluencersCandidacies {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    // select2 for display & categories
    $(".display-select").select2({
      minimumResultsForSearch: -1
    });

    $(".categories-select").select2({
      tags: true,
      closeOnSelect: false,
    })

    $(".status-select").select2({
      minimumResultsForSearch: -1,
      templateResult: self.formatState,
      templateSelection: self.formatState
    });

    $("#search-button").on('click', function(e){
      e.preventDefault();
      var url = `${window.location.href.split('?')[0]}-html?${$("#toolbar-form").serialize()}`
      $.ajax({url: url, success: function(result){
          $("#list").html(result);
      }});
    }); 
  }

  formatState (option) {
    var icon = $(option.element).attr('data-icon')
    var text = $(option.element).text();
    var val = $(`<i class="${icon}"></i> <span>${text}</span>`);
    return val;
  }

  onDestroy(){
    $(".display-select").select2('destroy');
    $(".status-select").select2('destroy');
    $(".categories-select").select2('destroy');
  }
}