export default class InfluencersFindCampaigns {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    const urlParams = new URLSearchParams(window.location.search);

    // select2 for display & categories
    $(".display-select").select2({
      minimumResultsForSearch: -1
    });

    $(".categories-select").select2({
      tags: true,
      closeOnSelect: false,
    })

    // default value by params
    $(".categories-select").val(self.getCategories(urlParams)).trigger('change');

    // switch
    $('.custom-control-input')[0].checked = false;
    $('#switch').on('change', function() {
      var count = null
      if (!$('.custom-control-input')[0].checked) {
        $('.already-applied').removeClass('d-none').addClass('d-block');
        count = parseInt($("#campaigns-count").text());
        count += $('.already-applied').length;
        $("#campaigns-count").html(count);
        if (count > 1){
          $("#campaigns-label").html("campagnes trouvées")
        }
      } else {
        $('.already-applied').addClass('d-none').removeClass('d-block');
        count = parseInt($("#campaigns-count").text());
        count -= $('.already-applied').length
        $("#campaigns-count").html(count);
        if (count <= 1){
          $("#campaigns-label").html("campagne trouvée")
        }
      }
    });

    $("#search-button").on('click', function(e){
      e.preventDefault();
      var url = `${window.location.href.split('?')[0]}-html?${$("#toolbar-form").serialize()}`
      $.ajax({url: url, success: function(result){
          $("#list").html(result);
      }});
    }); 
  }
  
  getCategories(urlParams){
    var categories = [];
    urlParams.forEach(function(v, k){
      if (k == "categories[]"){
        categories.push(v);
      }
    });
    return categories;
  }

  onDestroy(){
    $(".display-select").select2("destroy");
    $(".categories-select").select2("destroy");
  }
}